import React from 'react';

const IconUnlinkSubsheet = ({
   style = {},
   width = '100%',
   height = '100%',
   classes = '',
   viewBox = '0 0 512 512',
   onClickFn,
   onMouseDownFn,
}) => {
   const allClasses = 'text-subdued-blue hover:text-vibrant-blue cursor-pointer ' + classes;
   return (
      <div className={allClasses} onClick={onClickFn} onMouseDown={onMouseDownFn}>
         <svg
            style={style}
            height={height}
            width={width}
            viewBox={viewBox}
            className="fill-current"
            xmlns="http://www.w3.org/2000/svg">
            <g>
               <path d="m323.5 402.909c-7.18 4.146-9.639 13.314-5.493 20.493l30 51.958c4.149 7.185 13.341 9.624 20.493 5.491 7.178-4.146 9.639-13.314 5.493-20.491l-30-51.958c-4.147-7.194-13.359-9.668-20.493-5.493z" />
               <path d="m143.5 31.146c-7.18 4.146-9.639 13.314-5.493 20.493l30 51.956c4.149 7.185 13.341 9.628 20.493 5.493 7.178-4.146 9.639-13.314 5.493-20.493l-30-51.956c-4.162-7.194-13.345-9.638-20.493-5.493z" />
               <path d="m168.007 408.402-30 51.958c-4.146 7.178-1.686 16.346 5.493 20.491 7.139 4.125 16.337 1.705 20.493-5.491l30-51.958c4.146-7.18 1.685-16.348-5.493-20.493-7.18-4.175-16.362-1.701-20.493 5.493z" />
               <path d="m323.5 109.089c7.139 4.125 16.337 1.705 20.493-5.493l30-51.956c4.146-7.18 1.685-16.348-5.493-20.493-7.192-4.146-16.348-1.701-20.493 5.493l-30 51.956c-4.146 7.179-1.687 16.347 5.493 20.493z" />
               <path d="m256 512c8.291 0 15-6.709 15-15v-61c0-8.293-6.709-15-15-15-8.293 0-15 6.707-15 15v61c0 8.291 6.707 15 15 15z" />
               <path d="m241 76c0 8.291 6.707 15 15 15 8.291 0 15-6.709 15-15v-61c0-8.293-6.709-15-15-15-8.293 0-15 6.707-15 15z" />
               <path d="m15 286h151c8.282 0 15-6.718 15-15v-30c0-8.284-6.718-15-15-15h-151c-8.284 0-15 6.716-15 15v30c0 8.282 6.716 15 15 15z" />
               <path d="m255.376 183.466 5.004-30.007c1.536-9.093-5.511-17.459-14.797-17.459h-79.583c-36.216 0-66.513 25.809-73.482 60h148.063c7.331 0 13.59-5.301 14.795-12.534z" />
               <path d="m220.374 363.468 5.001-30.002c1.525-9.142-5.526-17.466-14.795-17.466h-118.062c6.969 34.191 37.266 60 73.482 60h39.578c7.332 0 13.59-5.301 14.796-12.532z" />
               <path d="m497 226h-151c-8.284 0-15 6.716-15 15v30c0 8.282 6.716 15 15 15h151c8.282 0 15-6.718 15-15v-30c0-8.284-6.718-15-15-15z" />
               <path d="m346 136h-39.595c-7.332 0-13.59 5.301-14.795 12.534l-4.995 30.004c-1.521 9.14 5.528 17.462 14.795 17.462h118.072c-6.969-34.191-37.267-60-73.482-60z" />
               <path d="m419.482 316h-148.072c-7.332 0-13.59 5.303-14.795 12.537l-4.995 30.002c-1.527 9.064 5.475 17.461 14.795 17.461h79.585c36.215 0 66.513-25.809 73.482-60z" />
            </g>
         </svg>
      </div>
   );
};

export default IconUnlinkSubsheet;
