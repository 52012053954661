export const HAS_CHANGED_CELL = 'has_changed_cell';
export const HAS_ADDED_CELL = 'has_added_cell';
export const UPDATED_CELL = 'updated_cell';
export const UPDATED_CELL_VISIBILITY = 'updated_cell_visibility';
export const POSTING_UPDATED_CELLS = 'posting_updated_cells';
export const POSTING_DELETED_CELLS = 'posting_deleted_cells'
export const COMPLETED_SAVE_CELLS = 'completed_save_cells';
export const COMPLETED_SAVE_CELL = 'completed_save_cell';
export const COMPLETED_DELETE_CELLS = 'completed_delete_cells';
export const UPDATE_CELLS_FAILED = 'cells_update_failed';
export const DELETE_CELLS_FAILED = 'delete_cells_failed';
export const POSTING_DELETE_SUBSHEET_ID = 'posting_delete_subsheet_id';
export const COMPLETED_DELETE_SUBSHEET_ID = 'completed_delete_subsheet_id';
export const DELETE_SUBSHEET_ID_FAILED = 'delete_subsheet_id_failed';
export const CELLS_LOADED = 'cells_loaded';
export const ADDED_CELL_KEYS = 'added_cell_keys';
export const REMOVED_CELL_KEYS = 'removed_cell_keys';
export const CLEARED_ALL_CELL_KEYS = 'cleared_all_cell_keys';
export const CELLS_UPDATED = 'cells_updated';
export const CELLS_REDRAW_COMPLETED = 'cells_redraw_completed';
export const UPDATED_END_OF_ROW_CELL = 'updatedEndOfRowCell';