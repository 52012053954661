export const REPLACED_ALL_METADATA = 'replace_all_metadata';
export const POSTING_UPDATED_METADATA = 'posting_updated_metadata';
export const CLEAR_METADATA = 'clear_metadata';
export const COMPLETED_SAVE_METADATA = 'completed_save_metadata';
export const METADATA_UPDATE_FAILED = 'metadata_update_failed';
export const HAS_CHANGED_METADATA = 'has_changed_metadata';
export const UPDATED_FROZEN_ROWS = 'updated_frozen_rows';
export const UPDATED_FROZEN_COLUMNS = 'updated_frozen_columns';
export const REPLACED_FROZEN_ROWS = 'replaced_frozen_rows';
export const REPLACED_FROZEN_COLUMNS = 'replaced_frozen_columns';
export const UPDATED_COLUMN_VISIBILITY = 'updated_column_visibility';
export const REPLACED_COLUMN_VISIBILITY = 'replaced_column_visibility';
export const UPDATED_ROW_VISIBILITY = 'updated_row_visibility';
export const REPLACED_ROW_VISIBILITY = 'replaced_row_visibility';
export const UPDATED_COLUMN_FILTERS = 'updated_column_filters';
export const REPLACED_COLUMN_FILTERS = 'replaced_column_filters';
export const UPDATED_ROW_FILTERS = 'updated_row_filters';
export const REPLACED_ROW_FILTERS = 'replaced_row_filters';
export const RESET_VISIBLITY = 'reset_visibility';
export const UPDATED_TOTAL_COLUMNS = 'updated_total_columns';
export const UPDATED_TOTAL_ROWS = 'updated_total_rows';
export const ROW_MOVED = 'row_moved';
export const ROW_MOVED_TO = 'row_moved_to';
export const COLUMN_MOVED = 'column_moved';
export const COLUMN_MOVED_TO = 'column_moved_to';
export const UPDATED_ROW_ORDER = 'updated_row_order';
export const UPDATED_COLUMN_ORDER = 'updated_column_order';
export const REPLACED_ROW_HEIGHTS = 'replaced_row_heights';
export const REPLACED_COLUMN_WIDTHS = 'replaced_column_widths';
export const UPDATED_COLUMN_WIDTH = 'updated_column_width';
export const UPDATED_ROW_HEIGHT = 'updated_row_height';
export const UPDATED_AXIS_ITEM_TOOL = 'updated_axis_item_tool';
export const HIDE_AXIS_ITEM_TOOL = 'hide_axis_item_tool';
export const UPDATED_PARENT_SHEET_ID = 'updatd_parent_sheet_id';
export const UPDATED_METADATA_ERROR_MESSAGE = 'updated_metadata_error_message';
export const CLEARED_METADATA_ERROR_MESSAGE = 'cleared_metadata_error_message';